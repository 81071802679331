<template>
  <div class="card mb-5 mb-xl-10">
    <div
      class="card-header border-0 cursor-pointer"
      role="button"
      data-bs-toggle="collapse"
      data-bs-target="#kt_account_profile_details"
      aria-expanded="true"
      aria-controls="kt_account_profile_details"
    >
      <div class="card-title m-0">
        <h3 class="fw-bolder m-0">{{$t("Détails de votre profil utilisateur")}}</h3>
      </div>
    </div>
    <div id="kt_account_profile_details" class="collapse show">
      <Form
        id="kt_account_profile_details_form"
        class="form"
        novalidate="novalidate"
        @submit="saveChanges1()"
        :validation-schema="profileDetailsValidator"
      >
        <div class="card-body border-top p-9">
          <div class="row mb-6">
            <label class="col-lg-4 col-form-label required fw-bold fs-6"
              >{{$t("Prénom Nom")}}</label
            >
            <div class="col-lg-8">
              <div class="row">
                <div class="col-lg-6 fv-row">
                  <Field
                    type="text"
                    readonly
                    name="uti_c_prenom"
                    class="
                      form-control form-control-lg form-control-solid
                      mb-3 mb-lg-0
                    "
                    :placeholder="$t('Prénom')"
                    v-model="profileDetails.uti_c_prenom"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="uti_c_prenom" />
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 fv-row">
                  <Field
                    type="text"
                    readonly
                    name="uti_c_nom"
                    class="form-control form-control-lg form-control-solid"
                    :placeholder="$t('Nom de famille')"
                    v-model="profileDetails.uti_c_nom"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="uti_c_nom" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-6">
            <label class="col-lg-4 col-form-label fw-bold fs-6">
              <span>{{$t("Téléphone Fixe")}}</span>
            </label>
            <div class="col-lg-8 fv-row">
              <Field
                type="tel"
                readonly
                name="uti_c_phone"
                class="form-control form-control-lg form-control-solid"
                :placeholder="$t('Téléphone')"
                v-model="profileDetails.uti_c_phone"
              />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="uti_c_phone" />
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-6">
            <label class="col-lg-4 col-form-label fw-bold fs-6"
              >{{$t("Votre Service")}}</label
            >
            <div class="col-lg-8 fv-row">
              <Field
                readonly
                type="text"
                name="uti_c_service"
                class="form-control form-control-lg form-control-solid"
                :placeholder="$t('Service')"
                v-model="profileDetails.uti_c_service"
              />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="uti_c_service" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer d-flex justify-content-end py-6 px-9">
          <!--
          <button
            type="submit"
            id="kt_account_profile_details_submit"
            ref="submitButton1"
            class="btn btn-primary"
          >
            <span class="indicator-label"> Enregistrer les changements </span>
            <span class="indicator-progress">
              Veuillez patientez...
              <span
                class="spinner-border spinner-border-sm align-middle ms-2"
              ></span>
            </span>
          </button>
          -->
        </div>
      </Form>
    </div>
  </div>
  <div class="card mb-5 mb-xl-10">
    <div
      class="card-header border-0 cursor-pointer"
      role="button"
      data-bs-toggle="collapse"
      data-bs-target="#kt_account_signin_method"
    >
      <div class="card-title m-0">
        <h3 class="fw-boldest m-0">{{$t("Profil de connexion")}}</h3>
      </div>
    </div>
    <div id="kt_account_signin_method" class="collapse show">
      <div class="card-body border-top p-9">
        <div class="d-flex flex-wrap align-items-center mb-8">
          <div id="kt_signin_email" :class="{ 'd-none': emailFormDisplay }">
            <div class="fs-4 fw-boldest mb-1">{{$t("Votre adresse Email")}}</div>
            <div class="fs-6 fw-bold text-gray-600">
              {{ profileDetails.uti_c_mail }}
            </div>
          </div>
          <div
            id="kt_signin_email_edit"
            :class="{ 'd-none': !emailFormDisplay }"
            class="flex-row-fluid"
          >
            <Form
              id="kt_signin_change_email"
              class="form"
              novalidate="novalidate"
              @submit="updateEmail()"
              :validation-schema="changeEmail"
            >
              <div class="row mb-6">
                <div class="col-lg-6 mb-4 mb-lg-0">
                  <div class="fv-row mb-0">
                    <label
                      for="emailaddress"
                      class="form-label fs-6 fw-bolder mb-3"
                      >{{$t("Veuillez saisir votre adresse Email")}}</label
                    >
                    <Field
                      type="email"
                      class="
                        form-control form-control-lg form-control-solid
                        fw-bold
                        fs-6
                      "
                      id="emailaddress"
                      :placeholder="$t('Email Address')"
                      name="emailaddress"
                      v-model="profileDetails.uti_c_newmail"
                    />
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="emailaddress" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="fv-row mb-0">
                    <label
                      for="confirmemailpassword"
                      class="form-label fs-6 fw-bolder mb-3"
                      >{{$t("Confirmer votre mot de passe")}}</label
                    >
                    <Field
                      type="password"
                      class="
                        form-control form-control-lg form-control-solid
                        fw-bold
                        fs-6
                      "
                      name="confirmemailpassword"
                      v-model="profileDetails.uti_c_password"
                      id="confirmemailpassword"
                    />
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="confirmemailpassword" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex">
                <button
                  id="kt_signin_submit"
                  type="submit"
                  ref="updateEmailButton"
                  class="btn btn-primary me-2 px-6"
                >
                  <span class="indicator-label"> {{$t("Modifier votre Email")}} </span>
                  <span class="indicator-progress">
                    {{$t("Veuillez patienter...")}}
                    <span
                      class="spinner-border spinner-border-sm align-middle ms-2"
                    ></span>
                  </span>
                </button>
                <button
                  id="kt_signin_cancel"
                  type="button"
                  class="btn btn-color-gray-400 btn-active-light-primary px-6"
                  @click="emailFormDisplay = !emailFormDisplay"
                >
                  {{$t("Annuler")}}
                </button>
              </div>
            </Form>
          </div>
          <div
            id="kt_signin_email_button"
            :class="{ 'd-none': emailFormDisplay }"
            class="ms-auto"
          >
          <!--
            <button
              class="btn btn-light fw-boldest px-6"
              @click="emailFormDisplay = !emailFormDisplay"
            >
              Changer votre adresse mail
            </button>
            -->
          </div>
        </div>
        <div class="d-flex flex-wrap align-items-center mb-8">
          <div
            id="kt_signin_password"
            :class="{ 'd-none': passwordFormDisplay }"
          >
            <div class="fs-4 fw-boldest mb-1">{{$t("Mot de passe")}}</div>
            <div class="fs-6 fw-bold text-gray-600">************</div>
          </div>
          <div
            id="kt_signin_password_edit"
            class="flex-row-fluid"
            :class="{ 'd-none': !passwordFormDisplay }"
          >
            <div class="fs-6 fw-bold text-gray-600 mb-4">
              {{$t("Le mot de passe doit faire minimum 8 caractères.")}}
            </div>
            <Form
              id="kt_signin_change_password"
              class="form"
              novalidate="novalidate"
              @submit="updatePassword()"
              :validation-schema="changePassword"
            >
              <div class="row mb-6">
                <div class="col-lg-4">
                  <div class="fv-row mb-0">
                    <label
                      for="currentpassword"
                      class="form-label fs-6 fw-bolder mb-3"
                      >{{$t("Mot de passe actuel")}}</label
                    >
                    <Field
                      type="password"
                      class="
                        form-control form-control-lg form-control-solid
                        fw-bold
                        fs-6
                      "
                      name="currentpassword"
                      id="currentpassword"
                      v-model="profileDetails.uti_c_password"
                    />
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="currentpassword" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="fv-row mb-0">
                    <label
                      for="newpassword"
                      class="form-label fs-6 fw-bolder mb-3"
                      >{{$t("Nouveau mot de passe")}}</label
                    >
                    <Field
                      type="password"
                      class="
                        form-control form-control-lg form-control-solid
                        fw-bold
                        fs-6
                      "
                      name="newpassword"
                      id="newpassword"
                      v-model="profileDetails.uti_c_password_new"
                    />
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="newpassword" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="fv-row mb-0">
                    <label
                      for="confirmpassword"
                      class="form-label fs-6 fw-bolder mb-3"
                      >{{$t("Confirmation de votre nouveau mot de passe")}}</label
                    >
                    <Field
                      type="password"
                      class="
                        form-control form-control-lg form-control-solid
                        fw-bold
                        fs-6
                      "
                      name="confirmpassword"
                      id="confirmpassword"
                      v-model="profileDetails.uti_c_password_new_confirm"
                    />
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="confirmpassword" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex">
                <button
                  id="kt_password_submit"
                  type="submit"
                  ref="updatePasswordButton"
                  class="btn btn-primary me-2 px-6"
                >
                  <span class="indicator-label">
                    {{$t("Modifier votre mot de passe")}}
                  </span>
                  <span class="indicator-progress">
                    {{$t("Veuillez patienter...")}}
                    <span
                      class="spinner-border spinner-border-sm align-middle ms-2"
                    ></span>
                  </span>
                </button>
                <button
                  id="kt_password_cancel"
                  type="button"
                  @click="passwordFormDisplay = !passwordFormDisplay"
                  class="btn btn-color-gray-400 btn-active-light-primary px-6"
                >
                  {{$t("Annuler")}}
                </button>
              </div>
            </Form>
          </div>
          <div
            id="kt_signin_password_button"
            class="ms-auto"
            :class="{ 'd-none': passwordFormDisplay }"
          >
            <button
              @click="passwordFormDisplay = !passwordFormDisplay"
              class="btn btn-light fw-boldest"
            >
              {{$t("Changer votre mot de passe")}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import mAxiosApi from "@/api";

import store from "@/store";
import { Mutations } from "@/store/enums/StoreEnums";
import { useI18n } from "vue-i18n";

interface ProfileDetails {
  uti_c_prenom: string;
  uti_c_nom: string;
  uti_c_phone: string;
  uti_c_service: string;
  uti_c_mail: string;
  uti_c_newmail: string;
  uti_c_password: string;
  uti_c_password_new: string;
  uti_c_password_new_confirm: string;
}

export default defineComponent({
  name: "account-settings",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup() {
    const { t } = useI18n()
    const submitButton1 = ref<HTMLElement | null>(null);
    const updateEmailButton = ref<HTMLElement | null>(null);
    const updatePasswordButton = ref<HTMLElement | null>(null);

    const emailFormDisplay = ref(false);
    const passwordFormDisplay = ref(false);

    const profileDetailsValidator = Yup.object().shape({
      uti_c_prenom: Yup.string().nullable().required(t("Le prénom est obligatoire")),
      uti_c_nom: Yup.string().nullable().required(t("Le nom est obligatoire")),
      uti_c_phone: Yup.string().nullable().label("Télephone Fixe"),
      uti_c_service: Yup.string().nullable().label("CCO ID"),
    });

    const changeEmail = Yup.object().shape({
      emailaddress: Yup.string().required().email().label("Email"),
      confirmemailpassword: Yup.string().required().label("Password"),
    });

    const changePassword = Yup.object().shape({
      currentpassword: Yup.string()
        .required(t("Mot de passe actuel obligatoire"))

        .label("Current password"),
      newpassword: Yup.string()
        .min(8)
        .required(t("Mot de passe actuel obligatoire"))

        .label("Password"),
      confirmpassword: Yup.string()
        .min(8)
        .required(t("Confirmation du nouveau Mot de passe obligatoire"))

        .oneOf(
          [Yup.ref("newpassword"), null],
          t("Les nouveaux mots des passes doivent être identiques")

        )
        .label("Password Confirmation"),
    });

    const profileDetails = ref<ProfileDetails>({
      uti_c_prenom: "",
      uti_c_nom: "",
      uti_c_phone: "",
      uti_c_service: "",
      uti_c_mail: "",
      uti_c_newmail: "",
      uti_c_password: "",
      uti_c_password_new: "",
      uti_c_password_new_confirm: "",
    });

    const saveChanges1 = () => {
      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        mAxiosApi
          .post("updateProfil", profileDetails)
          .then(({ data }) => {
            // console.log(data["results"]);
            submitButton1.value?.removeAttribute("data-kt-indicator");

            store.commit(Mutations.SET_USER, data["results"]);

            Swal.fire({
              text: t('Changement de vos informations personnelles correctement effectué !'),
              icon: "success",
              confirmButtonText: t('Ok'),
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-light-primary",
              },
            });
          })
          .catch((err) => {
            console.error(err);
          });
      }
    };

    const updateEmail = () => {
      if (updateEmailButton.value) {
        // Activate indicator
        updateEmailButton.value.setAttribute("data-kt-indicator", "on");

        mAxiosApi
          .post("updateMail", profileDetails)
          .then(({ data }) => {
            updateEmailButton.value?.removeAttribute("data-kt-indicator");

            if (data["results"] !== 0) {
              store.commit(Mutations.SET_USER, data["results"]);
              emailFormDisplay.value = false;
              profileDetails.value.uti_c_mail = profileDetails.value.uti_c_newmail;
              Swal.fire({
                text: t('Changement de votre Email correctement effectué !'),
                icon: "success",
                confirmButtonText: t('Ok'),
                buttonsStyling: false,
                customClass: {
                  confirmButton: "btn btn-light-primary",
                },
              });
            } else {
              Swal.fire({
                text: t('Erreur lors du chagement de mail. Veuillez vérifier votre adresse Email et votre mot de passe, svp.'),
                icon: "error",
                confirmButtonText: t('Ok'),
                buttonsStyling: false,
                customClass: {
                  confirmButton: "btn btn-light-primary",
                },
              });
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    };

    const updatePassword = () => {
      if (updatePasswordButton.value) {
        // Activate indicator
        updatePasswordButton.value.setAttribute("data-kt-indicator", "on");

        mAxiosApi
          .post("password", {
            username: profileDetails.value.uti_c_mail,
            password: profileDetails.value.uti_c_password,
            newPassword: profileDetails.value.uti_c_password_new,
          })
          .then(({ data }) => {
            // console.log(data);
            updatePasswordButton.value?.removeAttribute("data-kt-indicator");
            passwordFormDisplay.value = false;
            Swal.fire({
              text: t('Changement de votre mot de passe correctement effectué !'),
              icon: "success",
              confirmButtonText: t('Ok'),
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-light-primary",
              },
            });
          })
          .catch(() => {
            updatePasswordButton.value?.removeAttribute("data-kt-indicator");
            Swal.fire({
              text: t('Erreur lors du chagement de mot de passe. Veuillez vérifier vos informations et réessayer, svp.'),
              icon: "error",
              confirmButtonText: t('Ok'),
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-light-primary",
              },
            });
          });
      }
    };

    onMounted(async () => {
      setCurrentPageBreadcrumbs("Paramètres du compte", [""]);
      let meAndMyself = await getAxios("/me");

      profileDetails.value.uti_c_prenom = meAndMyself.uti_c_prenom;
      profileDetails.value.uti_c_nom = meAndMyself.uti_c_nom;
      profileDetails.value.uti_c_phone = meAndMyself.uti_c_phone;
      profileDetails.value.uti_c_service = meAndMyself.uti_c_service;
      profileDetails.value.uti_c_mail = meAndMyself.uti_c_mail;
      profileDetails.value.uti_c_newmail = meAndMyself.uti_c_mail;
      profileDetails.value.uti_c_password = "";
      profileDetails.value.uti_c_password_new = "";
      profileDetails.value.uti_c_password_new_confirm = "";
    });

    return {
      submitButton1,
      saveChanges1,
      profileDetails,
      emailFormDisplay,
      passwordFormDisplay,
      profileDetailsValidator,
      changeEmail,
      changePassword,
      updateEmailButton,
      updatePasswordButton,
      updateEmail,
      updatePassword,
    };
  },
});
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}
</script>
